import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtils } from '@app/core/services/utils/App-Utils';
import { AppConstants } from '@app/core/services/app-constants';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { AppInitService, V1MenuService, V1UtilitiesService } from '@app/core';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TwoFactorAuthComponent } from "../two-factor-auth/two-factor-auth.component";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Credentials } from '@app/types/TotopTyps';

import { ApienvService } from '@app/core/services/apienv.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showTotp: boolean = false;
  loginForm: FormGroup;
  isMobileView = false;
  showValidationError = false;
  wrongUsername = false;
  wrongPassword = false;
  hide: boolean = true;
  @ViewChild('appDescRef') appDescRef: ElementRef<HTMLDivElement>;

  public multipleAccount: boolean = false;
  public selectedAccount;
  public FirstTimeLoaded = true;
  public first_time = false;
  public CurrentYear = new Date().getUTCFullYear();
  isRedirectingFromOtherDomain:boolean=false;
  _user:string="";
  _password:string="";
  _referer_domain:string="";
  _token:string="";
  isLoggingIn:boolean= false;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public authenticationService: AuthenticationService,
    private router: Router,
    public _dialog: MatDialog,
    private v1MenuSer: V1MenuService, private v1Utitlu: V1UtilitiesService, private domain_account_access_righs: AppInitService,
    private http: HttpClient,
    private apiEnvService: ApienvService) {

    this.isMobileView = AppUtils.mobileCheck();
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });
    this
    this.baseURL = this.apiEnvService.API_Config.backendApiUrl
  }

  public baseURL: string = '';

  title: string = '';
  desc: string = '';
  isAnnouncementAvailable: boolean = false;
  showFull: boolean = false;
  limit: number = 530;

  selectedClient;
  ngOnInit() {
    this.authenticationService.saveSubDoaminToTabSession(location.href);
    this.authenticationService.saveDoaminToTabSession(location.origin);
    this.activatedRoute.paramMap.subscribe(params => {
      let _accId = params.get("accountId");
      let _username = params.get("username");
      let _pass = params.get("pass");
      if (_accId && _username && _pass) {
        console.log("Login Componet insisde param got", _accId, _username, _pass);
        this.AutoLoginAndAccountFetchingOnRedirection(this.v1Utitlu.dencodeStringForQueryParam(_accId),
          this.v1Utitlu.dencodeStringForQueryParam(_username),
          this.v1Utitlu.dencodeStringForQueryParam(_pass));
      } else {
        this.displayLoginForm = true;
      }
    });
    this.authenticationService.clinets = [];
    this.authenticationService.multipleUsers = [];
    // Mouseflow integration
    if ((window as any)._mfq) {
      (window as any)._mfq.push(['newPageView', '/login']);
    }

    if(!sessionStorage.getItem('selectedAccount')) {
       this.getActiveAnnouncement();
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this._token = params['token'];
      if(this._token){
        this.isRedirectingFromOtherDomain = true;
        this.validateToken();
      }
    });
  }
  validateToken() {
    this.authenticationService.validateToken(this._token).subscribe(
        (res) => {
            if (res && res.code === 200) {
                // Handle successful validation
                this._user = res.data.username;
                this._password = res.data.password;
                this._referer_domain = res.data.referer_domain;
                this.authenticationService.setLogoutURL(this._referer_domain);
                this.onLoginSubmit(true);
            } else {
                // Handle unsuccessful validation
                this.isRedirectingFromOtherDomain = false;
                console.error('Token validation failed:', res.message || 'Unknown error');
            }
        },
        (error) => {
            // Catch any errors
            this.isRedirectingFromOtherDomain = false;
            console.error('Token validation failed:', error);
        }
    );
  }

  getActiveAnnouncement(): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sYXJhdmVsLnRlc3RcL1wvbG9naW4iLCJpYXQiOjE2MTkwMzc2MzgsImV4cCI6MTYxOTA0MTIzOCwibmJmIjoxNjE5MDM3NjM4LCJqdGkiOiJ1eG9xUDVlQnF4ZndXMExBIiwic3ViIjozLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.BVgE7-oRaPKnTEs8sSXFAOh7BB65sHpGRELfS4trDbw'
    });  // param will be 2 from V3.1
    this.http.get(`${this.baseURL}announcement/show?visible_to=2`, { headers: headers }).subscribe((x: any) => {
      if (x.data) {
        this.title = x.data.title;
        this.desc = x.data.description;
        this.isAnnouncementAvailable = x.data.description.length > 0 ? true : false;
      } else {
        this.isAnnouncementAvailable = false;
        this.title = '';
        this.desc = '';
      }
    }, err => {
      console.error('Get Announcement Error', err);
    });
  }

  isTextOverflow(elementId: string): boolean {
    const elem = document.getElementById(elementId);
    if (elem) {
      return (elem.offsetHeight < elem.scrollHeight);
    }
    else {
      return false;
    }
  }

  viewMore(val: boolean): void {
    this.appDescRef.nativeElement.scrollTo({ top: 0 });
    console.log(this.appDescRef.nativeElement.scrollTop);
    this.showFull = val;
    this.limit = 530;
    if(val == true){
      this.limit = -100;
    }
  }

  clearFlags() {
    this.showValidationError = false;
    this.wrongUsername = false;
    this.wrongPassword = false;
  }
  AutoLoginAndAccountFetchingOnRedirection(redirectAccountId, _username, _pass) {
    sessionStorage.setItem('selectedAccount', redirectAccountId);
    console.log("Login Component : Auto Login Called", redirectAccountId, _username, _pass);
    this.displayLoginForm = false;
    this.authenticationService.login({ username: _username, password: _pass }).subscribe(y => {
      if (y.type == 'success' && y.nextData != 'clients' && y.nextData != 'menu') {
        this.multipleAccount = true;
        this.selectedAccount = redirectAccountId;
        this.LoginToSpecificAccount();
        this.displayLoginForm = true;
      } else {
        this.displayLoginForm = true;
      }
    },
      error => {
        this.displayLoginForm = true;
      });
  }
  displayLoginForm: boolean = false;
  validationMsg;

  onLoginSubmit(withQueryParms=false) {
    if (this.isLoggingIn) return; // Skip if already logging in
    this.isLoggingIn = true;
    const username= (withQueryParms)? this._user :this.loginForm.get('username').value;
    const password= (withQueryParms)? this._password :this.loginForm.get('password').value;
    const loginPayload: Credentials = {
      username: username,
      password: password
    };
    this.validationMsg = '';
    this.showValidationError = false;
    this.authenticationService.login(loginPayload).subscribe((data) => {
      if (data && data.type !== 'failed' && data.mfa_type !== null) {
        console.log("Call MFA Settings.");
        this.authenticationService.checkMFASettings(loginPayload).subscribe(res => {
          console.log(res);
          if (res.mfa_type === "TOTP" && res.status === "success") {
            this.showTotp = true;
            this.loginTemp({ ...res, credentials: loginPayload, loginRes: data });
          } else if (data.mfa_type == null) {
            this.accountSelection(loginPayload, data)
            if(data.nextData != 'clients' && data.nextData != 'accounts'){
              this.impMobCallOnLogin();
            }
          }
        });
      } else if (data.type == 'failed') {
        this.validationMsg = data.msg;
        this.showValidationError = true;
      }
      this.isLoggingIn = false;
      this.isRedirectingFromOtherDomain = false;
    });
    this.isLoggingIn = false;
    this.isRedirectingFromOtherDomain = false;
  }

  loginTemp(data: any) {
    this.showTotp = true;
    const totpDialoge = this._dialog.open(TwoFactorAuthComponent, {
      width: '650px', height: '200px',
      disableClose: true,
      data: data
    });

    totpDialoge.afterClosed().subscribe(res => {
      this.showTotp = !res.closeTotp;
      if (res.isValid) {
        console.log(res);
        this.accountSelection(res.credentials, res.loginRes);
      }
    });
  }

  accountSelection(loginPayload: Credentials, data: any) {
    if (data && data.type == 'success' && data.nextData != 'clients' && data.nextData != 'menu') {
      this.isAccountHasAccessRight(true, loginPayload).subscribe(ar => {
        if (ar) {
          if (ar.allowed) {
            this.multipleAccount = true;
          } else {
            this.validationMsg = 'You donot have access to this account';
            this.showValidationError = true;
            this.authenticationService.logout().subscribe();
            //tempSessionKey:cmds[1].gconn
            if (ar.tempSessionKey) {
              this.logoutFromTempSessionAsync(ar.tempSessionKey)
            }
          }
        }
      });
    } else if (data.type == 'success' && data.nextData == 'clients') {
      this.isAccountHasAccessRight(false, loginPayload).subscribe(ar => {
        if (ar) {
          if (ar.allowed) {
            this.multipleAccount = false;
            this.accountSelected = true;
          } else {
            this.validationMsg = 'You donot have access to this account';
            this.showValidationError = true;
            this.authenticationService.logout().subscribe();
          }
          if (ar.tempSessionKey) {
            this.logoutFromTempSessionAsync(ar.tempSessionKey)
          }
        }
      });
    } else if (data.type == 'success' && data.nextData == 'menu') {
      this.isAccountHasAccessRight(false, loginPayload).subscribe(ar => {
        if (ar) {
          if (ar.allowed) {
            let routeName = '/';
            this.router.navigate([routeName]).then(x => {
              this.authenticationService.afterPageLoaded.emit(x);
            });
          } else {
            this.validationMsg = 'You donot have access to this account';
            this.showValidationError = true;
            this.authenticationService.logout().subscribe();
          }
          if (ar.tempSessionKey) {
            this.logoutFromTempSessionAsync(ar.tempSessionKey)
          }
        }
      });
    }
  }

  accountSelected: boolean = false;
  LoginToSpecificUser() {
    this.accountSelected = false;
    if (this.selectedAccount && this.selectedAccount.length > 0) {
      console.log(this.selectedAccount);
      //If already account selected and parsed clients
      if (this.authenticationService.clinets && this.authenticationService.clinets.length > 0) {
        this.authenticationService.clinets = [];
        if (this.authenticationService.gConn) {
          let tempGCon = this.authenticationService.gConn;
          this.authenticationService.gConn = undefined;
          this.authenticationService.logoutCMD(tempGCon).subscribe();
        }
        this.ProcessLoginWithURLAccountAssociation(this.selectedAccount);
      } else {
        this.authenticationService.clinets = [];
        this.ProcessLoginWithURLAccountAssociation(this.selectedAccount);
      }
    }
  }
  ProcessLoginWithURLAccountAssociation(selectedAccountId) {
    this.v1Utitlu.isEnviornmentMigrated(this.selectedAccount).subscribe(z => {
      let _prefix = 'http://';
      let _domain = '.spherewms.com/';
      if (z == 'yes') {
        let _association = this.domain_account_access_righs.subdomain_account_mapping.CheckAccountURLAssociation(selectedAccountId);
        if (_association.association) {
          this.LoginToSpecificAccount();
        } else {

          if (_association.isSubdomainExistInConfig) {
            //Redirect To Subdomain from here, beacuse subdomain exist
            window.location.href = _prefix + _association.subDomain + _domain + 'login/' + this.v1Utitlu.encodeStringForQueryParam(selectedAccountId) + '/' + this.v1Utitlu.encodeStringForQueryParam(this.authenticationService.currentUserValue.masterUser) + '/' + this.v1Utitlu.encodeStringForQueryParam(this.authenticationService.currentUserValue.password);
          } else {
            if (_association.subDomain) {
              //Check if subdmain URL exist other wise redirect to old v1-app
              this.v1Utitlu.isSubdomainIsValidAndExist(_prefix + _association.subDomain).then(x => {
                if (x) {
                  //Subdomain Exist Redirect To Subdomain
                  window.location.href = _prefix + _association.subDomain + _domain + 'login/' + this.v1Utitlu.encodeStringForQueryParam(selectedAccountId) + '/' + this.v1Utitlu.encodeStringForQueryParam(this.authenticationService.currentUserValue.masterUser) + '/' + this.v1Utitlu.encodeStringForQueryParam(this.authenticationService.currentUserValue.password);
                } else {
                  //Redirect To old v1
                  // window.location.href = _prefix + 'v1' + _domain;
                  this.LoginToSpecificAccount();
                }
              });

            } else {
              //Redirect To v1 on exceptional case
              window.location.href = _prefix + 'v1' + _domain;
            }
          }
        }
      } else if (z == 'no') {
        //Redirect To v1 on exceptional case
        window.location.href = _prefix + 'v1' + _domain;
      } else {
        window.location.reload();
      }
    });

  }
  LoginToSpecificAccount() {
    this.authenticationService.LoginToSpecificAccount(this.selectedAccount).subscribe(x => {
      this.authenticationService.ParseLoginDetail(x).subscribe(y => {
        if (y.isClientSelection == true) {
          this.accountSelected = true;
        } else {
          if (y.isDashboard == true) {
            this.authenticationService.onDashboardResponseRedirectToMenu(y.response).subscribe(tt => {
              this.impMobCallOnLogin();
            });
          } else {
            this.impMobCallOnLogin();
          }
        }
         this.getActiveAnnouncement();
      });
    });

  }

  impMobCallOnLogin() {
    let routeName = '/';
    this.router.navigate([routeName]).then(_x => {
      this.authenticationService.adminBackendAPI().subscribe(adminData => {
        this.authenticationService.isAdminUser = adminData.menu_passwd_no;
        if (adminData.start_menu_id == "CFM100" || adminData.menu_options == "noxit") {
          this.authenticationService.afterPageLoaded.emit();
        } else {
          this.authenticationService.impMenufunc(adminData.menu_passwd_no).subscribe(_y => {
            this.authenticationService.mobMenufunc().subscribe(_z => {
              this.v1MenuSer.navigateToMenuSection().subscribe(_o => {
                this.authenticationService.afterPageLoaded.emit();
              })
            })
          })
        }

        // if (adminData.menu_options == "noxit") {
        //   this.authenticationService.impMenufunc(adminData.menu_passwd_no).subscribe(_y => {
        //     this.authenticationService.mobMenufunc().subscribe(_z => {
        //       this.v1MenuSer.navigateToMenuSection().subscribe(_o => {
        //         this.authenticationService.afterPageLoaded.emit();
        //       })
        //     })
        //   })
        // } else {
        //   this.authenticationService.afterPageLoaded.emit();
        // }
      })
    });
  }
  onAccountSelectionClear(event) {
    console.log("Account Clear Change", event);
    if (this.authenticationService.clinets && this.authenticationService.clinets.length > 0) {
      this.authenticationService.clinets = [];
      if (this.authenticationService.gConn) {
        let tempGCon = this.authenticationService.gConn;
        this.authenticationService.gConn = undefined;
        this.authenticationService.logoutCMD(tempGCon).subscribe();
      }
    }
  }
  clientSelectionChange() {
    this.authenticationService.ClientSelectionProcedure(this.selectedClient).subscribe(_x => {
      this.impMobCallOnLogin();
    });
  }
  ddlClinetScrollToEnd() {
    //Lazy loading From Clinet DropDown is removed
    // console.log("Client Selection dropdown Scrool to end");
    // if(this.authenticationService.clinetArrayConfig.available_more_rows){
    //     this.authenticationService.LoadMoreClients(this.v1Utitlu);
    // }
  }
  //on the basis of subdomain check if user belongs to account
  isAccountHasAccessRight(isAccountList: boolean, payload) {
    return new Observable<any>((observer) => {
      if (isAccountList) {
        observer.next({
          // no need to check for multiple access from file(domain_account_access_righs)
          // allowed: this.domain_account_access_righs.subdomain_account_mapping.hasAccessRights(this.authenticationService.multipleUsers.map(m => m.userName))
          allowed: true
        });
        observer.complete();
      } else {
        if (this.authenticationService.currentUserValue.designation && this.authenticationService.currentUserValue.designation.length > 0) {
          observer.next({
            // no need to check for single access from file(domain_account_access_righs)
            // allowed: this.domain_account_access_righs.subdomain_account_mapping.hasAccessRights(this.authenticationService.currentUserValue.designation)
            allowed: true
          });
          observer.complete();
        } else {
          let _envID = undefined;
          const iterativeReq: (sessionKey, cmd) => Observable<any> = (sessionKey, cmd) => {
            return this.authenticationService.gemxPost({
              gconn: sessionKey ? sessionKey : '',
              gcmd: cmd
            }).pipe(concatMap((res) => {
              let splitedRes = this.v1Utitlu.parseResponse(res);
              if (!sessionKey) {
                if (splitedRes.find(sk => sk.code == '001')) {
                  let _sessKey = splitedRes.find(sk => sk.code == '001').value;
                  if (_sessKey.match(/(002|003|004|005|007|008|009|133)/)) {
                    //pInArray.unshift(session);
                    console.warn('There is no code written for execution : Kisnly handlr this else statment too');
                    alert('not implemented!')
                  } else {
                    if (!_sessKey.match(/^\/tmp\/gemxsrv-[0-9]+-[0-9]+/)) {
                      alert('There was an issue reading the server response');
                      return;
                    } else {
                      sessionKey = _sessKey;
                      tempSessionKey = _sessKey;
                    }
                  }
                }
              }
              if (splitedRes.find(x => x.code == '006')) {
                _envID = splitedRes.find(x => x.code == '006').value;
                return new Observable<any>((ob) => { ob.next(_envID); ob.complete(); });
              } else if (splitedRes.find(x => x.code == '110')) {
                if (splitedRes.find(x => x.code == '006')) {
                  _envID = splitedRes.find(x => x.code == '006').value;
                  return new Observable<any>((ob) => { ob.next(_envID); ob.complete(); });
                } else {
                  return new Observable<any>((ob) => { ob.next("Not Found"); ob.complete(); });
                }
              } else if (splitedRes.find(x => x.code == '104')) {
                return iterativeReq(sessionKey, '[FS]0');
              } else if (splitedRes.find(x => x.code == '1320')) {
                return iterativeReq(sessionKey, '[RAW][ESC]');
              }
            }));
          }
          let tempSessionKey = undefined;
          iterativeReq(tempSessionKey, [payload.username, payload.password, '*', 'XML1'].join('|')).subscribe(actId => {
            if (actId == 'Not Found') {
              observer.next({
                allowed: false,
                tempSessionKey: tempSessionKey
              });
              observer.complete();
            } else {
              observer.next({
                allowed: this.domain_account_access_righs.subdomain_account_mapping.hasAccessRights(actId),
                tempSessionKey: tempSessionKey
              });
              observer.complete();
            }
          });
        }
      }
    });
  }
  //used to logout from temprory sessions that was created for purpose
  logoutFromTempSessionAsync(sessionKey) {
    if (sessionKey && sessionKey.length > 0) {
      this.authenticationService.logoutCMD(sessionKey).subscribe();
    }
  }
  ddlOnEnterPress(event, element) {
    console.log("Enter Key fired from Clinet DDl", event, element);
    this.authenticationService.SearchClients(element.searchTerm);
    element.searchTerm = '';
  }
  keydownInDropdown(event) {
    if (event.keyCode == 13) {
      return false;
    }
  }
  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    console.log('back button pressed', event);
    event.preventDefault();
    setTimeout(() => {
      this.router.navigate(['/login']);
      this.authenticationService.logout().subscribe();
      window.location.reload();
    }, 0);
  }
  // ClientSelectforNoAccounts(){
  //   this.authenticationService.ClientSelectionProcedureForNoAccounts(this.selectedClient).subscribe(x=>{
  //     let routeName = '/';
  //     this.router.navigate([routeName]);
  //   });

  // }
  showPwd() {
    this.hide = !this.hide;
  }
}
