import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService, V1MenuService } from '@app/core';
import { AppConstants } from '@app/core/services/app-constants';
import { AppRouteService } from '@app/core/services/app-route.service';
import { LoaderService } from '@app/core/services/loader.service';
import { UtilityService } from '@app/core/services/utility.service';
import { AppUtils } from '@app/core/services/utils/App-Utils';
import { V1UtilitiesService } from '@app/core/services/v1-utilities.service';
import { MSGBOXComponent } from '@app/modules/lookup/msg-box/msg-box.component';
import { ExternalScreenComponent } from '../external-screen/external-screen';
import { PrinterSettingsComponent } from '../printer-settings/printer-settings';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  toggle: boolean = false;
  ddlClinets = [];
  qfddOptions = [];
  selectedClient;
  showExtraClass = true;
  @ViewChild('ddlClients') clientsControl;
  @Input('Clinets') set _ddlClinets(value) {
    this.ddlClinets = value;
    var iframe: any = document.getElementById('jsd-widget');


    if (value && value.length > 0) {
      let selectedIndex = this.authenticationService.clinets.findIndex(x => x.isSelected == true);
      // console.log("On Client Setter Found Index*******",selectedIndex,this.authenticationService.clinets);
      if (selectedIndex !== -1) {
        this.selectedClient = this.ddlClinets[selectedIndex].id;
      }
      if (iframe) {
        iframe.classList.remove("with-out-clients");
        iframe.classList.add('with-clients');
      } else {
        let jhdScript = document.getElementById('jsd-widget-script');
        if (jhdScript) {
          jhdScript.setAttribute('class-position', this.ddlClinets.length > 0 ? 'with-clients' : 'with-out-clients')
        }
      }
    } else {
      if (iframe) {
        iframe.classList.remove("with-clients");
        iframe.classList.add('with-out-clients');

      } else {
        let jhdScript = document.getElementById('jsd-widget-script');
        if (jhdScript) {
          jhdScript.setAttribute('class-position', this.ddlClinets.length > 0 ? 'with-clients' : 'with-out-clients')
        }
      }
    }
  };
  @Output() menutoggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set menutoggle(value) {
    this.toggle = value;
  }
  @Output('clientSelectionchanged') _clientSelectionchanged = new EventEmitter<any>();
  userDetails: any;
  notificationIconPath = '';
  isMobile: boolean = false;
  formTitle: any;
  ddlQF = [];
  showQFlist: boolean = false;
  searchableQF: boolean = false;
  dropdown = false;
  iframe: any;

  constructor(
    public authenticationService: AuthenticationService,
    public _utilityService: UtilityService,
    public v1UtilitySer: V1UtilitiesService,
    public v1menuservice: V1MenuService,
    public _appRouteService: AppRouteService,
    public _dialog: MatDialog,
    private loaderService: LoaderService,
    public router: Router) {
    this.isMobile = AppUtils.mobileCheck();
  }

  ngOnInit() {
    this.userDetails = this.authenticationService.currentUserValue;
    console.log('iframe');

    if (this.authenticationService.currentUserValue.designation != 'redaspen-s' && this.authenticationService.currentUserValue.designation != 'redaspen') {
      this.notificationIconPath = '/assets/icon/notifications-red.svg';
      var iframe: any = document.getElementById('jsd-widget');
      if (iframe) {
        iframe.style.visibility = 'initial';
      } else {
        this.authenticationService.onMenuResponse.subscribe(x => {
          var iframe: any = document.getElementById('jsd-widget');
          if (iframe) {
            iframe.style.visibility = 'initial';
          }
        })
      }
    }
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  addTooltipClass() {
    let helpButtonContainer;
    this.iframe = document.getElementById('jsd-widget');
    if (this.iframe) {
      helpButtonContainer = this.iframe.contentWindow.document.getElementById('button-container');
      if (helpButtonContainer) {
        let reactRoot = this.iframe.contentWindow.document.getElementById('react-root');
        let helpButtonDocument = this.iframe.contentWindow.document.getElementById('react-root').childNodes[0];
        reactRoot.classList.add('custom-dimensions');
        helpButtonDocument.classList.add('tooltip');
        const para = this.iframe.contentWindow.document.createElement("span");
        const node = this.iframe.contentWindow.document.createTextNode("Submit a ticket");
        para.appendChild(node);
        para.classList.add('tooltiptext');
        helpButtonDocument.appendChild(para);
        return true;
      }
      else {
        return false
      }
    }
    else
      return false
  }

  async openPopover(ev: any) {
    // const popover = await this.popoverCtrl.create({
    //     component: UserPopoverComponent,
    //     cssClass: 'custom-user-popover',
    //     componentProps: {
    //       homeRef: this
    //     },
    //     event: ev,
    //     animated: true,
    //     showBackdrop: false,
    // });
    // popover.style.cssText = '--max-width: 200px;';

    // return await popover.present();
  }
  async logout() {
    // await this.popoverCtrl.dismiss();

    sessionStorage.removeItem('currentClient');
    this.authenticationService.logout().subscribe(x => {
      var iframe: any = document.getElementById('jsd-widget');
      if (iframe) {
        iframe.style.visibility = 'hidden';
      }
      const logout_url= this.authenticationService.getDomainFromSession();
      // remove referer domain from local storage
      this.authenticationService.removeRefererDomain()

      window.location.replace(`${logout_url}/login`);
    });
  }


  menuToggle() {
    this.toggle = true;
    this.menutoggleChange.emit(this.toggle);
    // this._utilityService.mbl_menuToogle = !this._utilityService.mbl_menuToogle;
  }
  clientSelectionChange() {
    //this._clientSelectionchanged.emit(this.selectedClient);
    setTimeout(() => {
      let selectedIndex = this.authenticationService.clinets.findIndex(x => x.id == this.selectedClient);
      let storageObj = {
        clientRowId: 0,
        lazyLoadedCount: 0
      }
      // console.log("On Client Setter Found Index*******",selectedIndex,this.authenticationService.clinets);
      if (selectedIndex !== -1) {
        storageObj.clientRowId = this.ddlClinets[selectedIndex].id;
        storageObj.lazyLoadedCount = this.ddlClinets[selectedIndex].lazyLoadedCount;
        sessionStorage.setItem('currentClient', JSON.stringify(storageObj));
        window.location.reload();
      }
    }, 10);
  }
  //Lazy Loading From Client DropDown Disbaled
  // ddlClinetScrollToEnd(){
  //   console.log("Clients From Header Scroll To End",this.clientsControl);
  //   if(this.authenticationService.clinetArrayConfig.available_more_rows){
  //     this.OpenClientLazyLoadConfirmationBox().subscribe(x=>{
  //       this.clientsControl.isOpen=true;
  //       if(x=='ok'){
  //         let isMenuState=this.v1menuservice.menu.menus.find(x=>(x.SubMenues.find(y=>y.isActive==true)!=undefined));
  //         if(this._utilityService.isAnyModuleRendered() || isMenuState){
  //           this._utilityService.navigate('/').then(nv=>{
  //             this.v1menuservice.menu.resetAllMenuesonSubChildLoad(undefined,undefined);
  //             this.v1menuservice.navigateToMenuSection(this.loaderService.lastResponse).subscribe(g=>{
  //               let tempCMDs=new Array(+(this.ddlClinets[this.ddlClinets.length-1].lazyLoadedCount)).fill('[FS][MORE]');
  //               tempCMDs.unshift('[MENU]0000');
  //               this.v1menuservice.doNavigation(tempCMDs).subscribe(fn=>{
  //                 this.authenticationService.LoadMoreClients(this.v1UtilitySer);
  //               })
  //             });
  //           });
  //         }else{
  //           this.authenticationService.LoadMoreClients(this.v1UtilitySer);
  //         }
  //       }
  //     });
  //   }
  // }
  OpenClientLazyLoadConfirmationBox() {
    let temObj = {
      isCustomConfirmationBox: true,
      _title: 'More Clients',
      body: ' Are you sure? <br>While loading more clients,system will navigate to start.',
      buttons: [{ text: 'Ok', cmd: '0', lowercaseText: 'ok', isCustomAction: true }, { text: 'Cancel', cmd: '0', lowercaseText: 'Cancel', isCustomAction: true }]
    }
    let msgDialouge = this._dialog.open(MSGBOXComponent, {
      data: temObj,
      width: '650px', height: '200px',
      id: 'open-msg-box' + Math.random().toString(36).substr(2),
      disableClose: true
    });
    return msgDialouge.afterClosed();
  }
  QuickFunctionSelected(event) {
    if (!this._utilityService.currentRouteIs('dashboard') || (this._utilityService.currentRouteIs('dashboard') && this.v1menuservice.stopDashboardPooling.getValue() == true)) {
      this._utilityService.onQuickFunctionSelected.next(event);
    } else {
      setTimeout(() => {
        this.authenticationService.QFSelected = undefined;
      }, 10);
    }
  }
  clearQF() {
    this.authenticationService.qfddItems = this.authenticationService.functionKeys;

    this.v1menuservice.navigateToMenuSection().subscribe(x => {
      this._utilityService.isAnyModuleRendered() ? this._utilityService.navigateToHome() : false;
    });
  }
  helpBtnClicked() {
    this.v1UtilitySer.open_window('https://userguide.spherewms.com/', 'SphereWMS V1 User Guide');
    // this.v1UtilitySer.open_window(this.authenticationService.accessCtrlSetting.helpURL.url, this.authenticationService.accessCtrlSetting.helpURL.winName);
  }
  dialouge: MatDialogRef<any>;
  OpenLookUp(waId, body, width?, height?) {
    //this._isActive=false;
    this.dialouge = this._dialog.open(ExternalScreenComponent, {
      //height: '210px',
      data: body,
      width: width ? width : '60%', height: height ? height : '85%',
      id: waId,
    });
    this.dialouge.afterClosed().subscribe(x => {
      // this._isActive=true;
      // this._ParseScreen(x,false);
    });
  }
  openChangePassword() {
    console.log("Change Password Clicked!");
    this.OpenLookUp('change-pass', { action: 'changePass' }, '600px', '560px');
  }

  printerSettingHandler() {
    this._dialog.open(PrinterSettingsComponent, {
      id: 'printer-settings',
      width: '600px',
      height: '560px'
    });
  }

  announcementHandler() {
    // this._appRouteService.setCurrentRouteName('announcement');
    this.router.navigate(['announcement']);
    // window.open('/announcement', '_blank');
  }

}
