<div class="grid-disbaled" *ngIf="disabled"></div>
<div #overview class="example-container">
    <!-- <div class="row tab-row" *ngIf="hasMoreHadder">
        <div class="col-12 grid-tab" grid-tab-def>
            <ng-content select="[grid-tab-buttons]"></ng-content>
        </div>
    </div> -->
    <table #scrollT cdkDropList id=tableGrid cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)"
        mat-table #outerSort="matSort" [dataSource]="dataSource" (scroll)="scrollEvent(scrollT)" multiTemplateDataRows
        class="mat-elevation-z8" matSort matSortActive="any" matSortDirection="asc">

        <ng-container *ngIf="hasExpendedView" matColumnDef="expandIcon" sticky>
            <th class="checkboxHead" mat-header-cell *matHeaderCellDef mat-button> </th>
            <td class="checkBoxData mat-wrap" mat-cell *matCellDef="let element">
                <mat-icon class="grid-icon" *ngIf="element != expandedElement"
                    (click)="toggleRow(element);$event.stopPropagation();">arrow_right</mat-icon>
                <mat-icon class="grid-icon" *ngIf="element == expandedElement"
                    (click)="toggleRow(element);$event.stopPropagation();">arrow_drop_down</mat-icon>
            </td>
        </ng-container>

        <ng-container *ngIf="isSelectableRow" matColumnDef="select" sticky>
            <th class="width-100" mat-header-cell *matHeaderCellDef class="text-center">
                <mat-checkbox class="gridSelectionChkbx gridSelectionChkbxHeader"
                    (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td class="mobile-label mat-wrap" mat-cell *matCellDef="let row">
                <mat-checkbox class="gridSelectionChkbx" (click)="$event.stopPropagation()"
                    (dblclick)="checkBoxDoubleClicked($event)" (change)="$event ? rowToogle(row) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container *ngFor="let column of columnsToDisplay ;trackBy: trackByIndex; let ind = index">
            <ng-container *ngIf="column.isVisible">
                <ng-container *ngIf="column.field=='status';else noStatusCol" matColumnDef="{{column.field}}" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef cdkDrag class="text-center" mat-button>{{column.displayName}}
                    </th>
                    <td class="mobile-label mat-wrap" mat-cell *matCellDef="let element">
                        <div class="order-status">
                            <div [attr.status]="element[column.field]">{{element[column.field]}}</div>
                        </div>
                    </td>
                </ng-container>

                <ng-template #noStatusCol [ngSwitch]="column.Stiky">
                    <!-- <ng-container *ngSwitchCase="'orderId'" matColumnDef="{{column.field}}" sticky>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag class="text-center"> {{column.displayName}} </th>
                  <td mat-cell *matCellDef="let element" class="grid-pointer" (click)="onOrderId(element)"> {{element[column.field]}} </td>
              </ng-container>
              <ng-container *ngSwitchCase="'lines'" matColumnDef="{{column.field}}">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag class="text-center">{{column.displayName}}</th>
                  <td mat-cell *matCellDef="let element" class="grid-pointer" (click)="toggleRow(element)">{{element[column.field]}}</td>
              </ng-container> -->
                    <ng-container *ngSwitchCase="'Start'" matColumnDef="{{column.field}}" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef [ngClass]="column.align?column.align:'text-center'"
                            mat-button> {{column.displayName}} </th>
                        <td class="mat-wrap" mat-cell *matCellDef="let element;let i=dataIndex" [class]="column.align"
                            [ngClass]="{'sorted':(sort?.active==column.field && sort.direction!=''),'grid-col-clickable':column.clickable}"
                            (click)="CellClicked(column.field,element,column.clickable,column.detailViewActionButton,i);$event.stopPropagation();"
                            [innerHtml]="element[column.field]">
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'End'" matColumnDef="{{column.field}}" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef [ngClass]="column.align?column.align:'text-center'"
                            mat-button> {{column.displayName}} </th>
                        <td class="mat-wrap" mat-cell *matCellDef="let element;let i=dataIndex" [class]="column.align"
                            [ngClass]="{'sorted':(sort?.active==column.field && sort.direction!=''),'grid-col-clickable':column.clickable}"
                            (click)="CellClicked(column.field,element,column.clickable,column.detailViewActionButton,i);$event.stopPropagation();"
                            [innerHtml]="element[column.field]">
                        </td>
                    </ng-container>
                    <!-- <ng-container *ngSwitchCase="'action'" matColumnDef="{{column.field}}" stickyEnd>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> {{column.displayName}} </th>
                  <td mat-cell *matCellDef="let element">
                      <div style="display: flex;align-items: center;">
                          <img style="cursor: pointer;" src="/assets/icon/edit-black.svg" (click)="editClicked(element)" />
                          <img style="cursor: pointer;padding-left: 10px;" src="/assets/icon/trash-2.svg" />
                      </div>
                  </td>
              </ng-container> This is Actionable Icons that has remove from standard-->
                    <!-- Fahad -->
                    <ng-container *ngSwitchDefault matColumnDef="{{column.field}}">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef
                            [ngClass]="column.align?column.align:'text-center'"
                            (contextmenu)="onContextMenu($event,column.field)">
                            <button #headerMenuBtn="matMenuTrigger" *ngIf="contextMenu" [matMenuTriggerFor]="contextMenu"
                                [matMenuTriggerData]="{field:column.field}" style="visibility: hidden; position: fixed"
                                [style.left]="contextMenuPosition.x"
                                [style.top]="contextMenuPosition.y"></button>{{column.displayName}}
                        </th>
                        <td class="mat-wrap" mat-cell *matCellDef="let element;let i=dataIndex" [class]="column.align"
                            [ngClass]="{'sorted':(sort?.active==column.field && sort.direction!=''),'grid-col-clickable':column.clickable}"
                            (click)="CellClicked(column.field,element,column.clickable,column.detailViewActionButton,i);$event.stopPropagation();"
                            [innerHtml]="element[column.field]">
                        </td>
                    </ng-container>
                </ng-template>
            </ng-container>
        </ng-container>
        <!-- Pivot Column -->
        <ng-container matColumnDef="pivot" stickyEnd>
            <th mat-header-cell *matHeaderCellDef mat-button class="pivot" [style]="{'min-width': '35px !important'}">
                <!--Grid Columns Rearrangements-->
                <!-- <img class="grid-pointer wms-ico" [matMenuTriggerFor]="menu" (menuOpened)="colsConfigOpend()" src="/assets/icon/add.svg" />
                <mat-menu #menu="matMenu">
                    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)" (click)="$event.stopPropagation();">
                        <div class="example-box" *ngFor="let dragCol of columnsToDisplay" cdkDrag [cdkDragDisabled]="!dragCol.isDragable">
                            <div>
                                <img src="/assets/icon/drag.svg" />
                                <mat-checkbox *ngIf="dragCol.isDragable" [(ngModel)]="dragCol.isVisible" style="position: relative;top: 4px;padding-right: 10px;" (click)="$event.stopPropagation()" (change)="updateColumnsData()">
                                </mat-checkbox>
                                <span>{{dragCol.displayName}}</span>
                            </div>
                        </div>
                    </div>
                </mat-menu>  -->
            </th>
            <td class="mat-wrap" mat-cell *matCellDef="let element" class="pivot">

                <img *ngIf="ShowEllipses" class="grid-pointer wms-ico" src="/assets/icon/more.svg"
                    [matMenuTriggerFor]="menuMore" alt="more" />

                <mat-menu class="noPad" #menuMore="matMenu">
                    <!-- <ng-container *ngTemplateOutlet="rowActionButtons;context: {$implicit: element}"></ng-container> -->
                    <!-- <ng-content select="[rowActions]"></ng-content> -->
                    <ng-container [ngTemplateOutlet]="rowActionButtons"
                        [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail" *ngIf="hasExpendedView">
            <td class="mat-wrap" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail" *ngIf="element.items?.data.length"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                        <table #innerTables matSortDisabled mat-table #innerSort="matSort" [dataSource]="element.items"
                            matSort>
                            <div *ngFor="let innerColumn of innerDisplayedColumns">
                                <ng-container *ngIf="innerColumn.field == 'images';else otherelements;"
                                    matColumnDef="{{innerColumn.field}}">
                                    <th mat-header-cell *matHeaderCellDef mat-button [matMenuTriggerFor]="aboveMenu">
                                        {{innerColumn.displayName}} </th>
                                    <td class="mat-wrap" mat-cell *matCellDef="let element"> <img
                                            src="/assets/icon/phone.svg" alt="phone" /></td>
                                </ng-container>
                                <ng-template #otherelements>
                                    <ng-container matColumnDef="{{innerColumn.field}}">
                                        <th mat-header-cell *matHeaderCellDef
                                            [ngClass]="innerColumn.align?innerColumn.align:'text-center'" mat-button>
                                            {{innerColumn.displayName}} </th>
                                        <td class="mat-wrap" mat-cell *matCellDef="let element"
                                            [class]="innerColumn.align"> {{element[innerColumn.field]}} </td>
                                    </ng-container>
                                </ng-template>
                            </div>
                            <tr mat-header-row *matHeaderRowDef="columnKeys"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnKeys;"></tr>
                        </table>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="initColumns; sticky:true"></tr>
        <!-- For Single Selection Row Behavior grid-row  [rowData]="element"-->
        <tr mat-row row-click-handler [rowData]="element"
            *matRowDef="let element; columns: initColumns; let i = dataIndex;"
            [class.grid-pointer]="isSingleSelectablerow" (click)="rowClicked.emit(element)" [class.even-rows]="(i%2)==0"
            [class.example-element-row]="element.items?.data.length"
            [class.example-expanded-row]="expandedElement === element" [id]="i" (dbClick)="rowDoubleClicked($event)"
            (contextmenu)="onRowContextMenu($event,element)">
        </tr>

        <ng-container *ngIf="hasExpendedView">
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </ng-container>

    </table>
    <mat-menu #rowContextMenu="matMenu">
        <ng-template matMenuContent let-aliasRowNumber="row">
            <ng-container *ngFor="let item of _rowContextItems">
                <button *ngIf="item.type=='menu'" mat-menu-item (click)="rowContextItemAction(item)">
                    {{item.text}}
                </button>
                <hr *ngIf="item.type=='lineBreak'" style="margin: 0 !important;">
            </ng-container>
        </ng-template>
    </mat-menu>
    <button #RowMenuBtn="matMenuTrigger" [matMenuTriggerFor]="rowContextMenu" [matMenuTriggerData]="{row:'0'}"
        style="visibility: hidden; position: fixed" [style.left]="rowContextMenuPosition.x"
        [style.top]="rowContextMenuPosition.y"></button>
    <div class="row" style="margin: 0px; background-color: white;">
        <div class="col-2 offset-5 load-all-rows-btn">
            <div *ngIf="hasMoreRows" (click)="LoadAllRows.emit(true);">Load more records</div>
        </div>
        <div class="col-3 offset-2">
            <mat-paginator hidePageSize=true hasNextPage=false [pageSizeOptions]="[5, 10, 25]" [length]="16"
                [pageSize]="50000" showFirstLastButtons=false></mat-paginator>
        </div>
    </div>
</div>
<!-- style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" -->
