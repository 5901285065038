import { HttpClient, HttpHeaders, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Credentials, MFAResponse, TOTPResponse, ValidateTOTP } from '@app/types/TotopTyps';
import { Router } from '@angular/router';
import { BehaviorSubject, from, observable, Observable, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ClientSelectionPopupComponent } from '../components/client-selection-popup/client-selection-popup.component';
import { ApienvService } from './apienv.service';
import { LoaderService } from './loader.service';
import { V1PrinterService } from './v1-printer.service';
import { V1UtilitiesService } from './v1-utilities.service';
import { environment } from '@env/environment.prod';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // private V1_BASE_URL = 'https://mr-181254162.test.v1.spherewms.com/scripts/';
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public multipleUsers: Array<any> = [];
  public isGlobalDashboardOnLanding: boolean = false;
  public isDashboardAccount: boolean = false;
  public gConn;
  public clinets = [];
  public clinetArrayConfig = {
    allow_search: false,
    available_more_rows: false
  }
  public functionKeys: { keynum, text, cmd }[] = [];
  public qfddItems: { keynum, text, cmd }[] = [];
  //This is binded to app-header --> Quick Functions Key
  public QFSelected;
  //dialouge id , where dialouge is start rendering Quick Fucntion Data
  quickFunctionDialougeId;
  public isAdminUser = '';
  public accessCtrlSetting: {
    bugReporter: boolean,
    help: boolean,
    changePassword: boolean,
    helpURL: { winName, url }
  } = {
      bugReporter: true,
      help: true,
      changePassword: true,
      helpURL: {
        winName: '',
        url: ''
      }
    };
  onMenuResponse = new EventEmitter<any>();
  onImpMenuResponse = new EventEmitter<any>();
  onMobMenuResponse = new EventEmitter<any>();
  afterPageLoaded = new EventEmitter<any>();
  clientNameFromMenu;
  clinetNameChanged = new EventEmitter<any>();

  public backendApiURL: string = '';
  //public clinetSelectionEvent=new EventEmitter<any>();
  constructor(private Http: HttpClient, private apiEnvService: ApienvService,
    private loaderService: LoaderService, private appTitleService: Title,
    public _router: Router,
    private v1Printer: V1PrinterService,
    private v1Utiltity: V1UtilitiesService,
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.onMenuResponse.subscribe(x => {
      this.ConstructFunctionKeysList(x);
    });
    this.backendApiURL = this.apiEnvService.API_Config.backendApiUrl;
  }



  // Send a POST request of x-www-form-urlencoded content
  public gemxPost(bodyData: object, isAsync?): Observable<any> {
    if (!this.loaderService.isLoading.value || isAsync) {
      // Request headers

      let headers = new HttpHeaders();
      if (isAsync) {
        headers = new HttpHeaders({
          'content-type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic 1234'
        })
      } else {
        headers = new HttpHeaders({
          'content-type': 'application/x-www-form-urlencoded'
        })
      }
      // Request body
      let body = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
      for (const k in bodyData) {
        body = body.set(k, bodyData[k]); //.replace(/\+/gi, '%2B')
      }

      return this.Http.post(this.apiEnvService.API_Config.gemxUrl + '/cgi-bin/gemxMobile.cgi', body, { headers: headers, responseType: 'text' });
    } else {
      return throwError({ requestCmd: bodyData, error: 'Async Request not alllowed' });
    }

  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
  public EnvVariablesTabSettAndToogleAccess(res: [any]) {
    let gemxSessionKeyObj = res.find(x => x.startsWith('001'));
    if (gemxSessionKeyObj) {
      if (this.gConn == undefined) {
        this.ClearAndSetSession(gemxSessionKeyObj);
      }
    }
    let envVariableExist = res.find(x => x.startsWith('006'));
    if (envVariableExist) {
      let envValue = envVariableExist.substring(3);
      this.SetUserDetailOnLocal({
        name: this.multipleUsers && this.multipleUsers.length > 0 ? this.multipleUsers.filter(z => z.userName == envValue)[0].title : '',
        designation: envValue,
        role: 'admin',
        imgUrl: '',
        companyLogo: '/assets/icon/spherewms.svg',
        masterUser: this.currentUserValue.masterUser,
        password: this.currentUserValue.password
      });
      // Load Printer Settings
      this.v1Printer.fetchAuthSettings(this.currentUserValue.designation, this.currentUserValue.masterUser);

      // Load Jira Ticket
      this.LoadJiraticket(this.currentUserValue.designation);
    }
    let titleExist = res.find(x => x.startsWith('112'));
    if (titleExist) {
      let sScreenname = titleExist.substring(3);
      this.appTitleService.setTitle(sScreenname);
    }
    let togleAccesexist = res.find(x => x.startsWith('008'));
    if (togleAccesexist) {
      let pColData = (togleAccesexist.substring(3)).split('|');
      if (typeof (pColData[1]) != 'undefined') {
        let settings = pColData[1].split('');
        /* Need to enable when all elements are implimented*/
        // if(settings[0] == 1)	$('.headerlink#toggleMenu').prop('disabled', true).hide();
        if (settings[1] == 1) this.accessCtrlSetting.bugReporter = false;
        if (settings[2] == 1) this.accessCtrlSetting.help = false;
        if (settings[3] == 1) this.accessCtrlSetting.changePassword = false;
      }

    }
    let helpURLExist = res.find(x => x.startsWith('128'));
    if (helpURLExist) {
      // set up help url.
      let act = parseInt(helpURLExist.substring(3, 4));
      let spltItm = (helpURLExist.substring(4)).split('|');
      var winname = spltItm[1];
      let helpURL = spltItm[2];

      if (this.accessCtrlSetting.help) {
        if (helpURL == "" || typeof (helpURL) == 'undefined') {
          //alert("No help defined for this account");
          this.accessCtrlSetting.help = false;
        } else {
          ///Override default sent from enviroment to be new default SS-347543
          if (helpURL.endsWith('faes/Default.htm')) {
            helpURL = 'https://help-v1.spherewms.com/v1/';
          }
          //replace old help url with new one as per v3.o standard
          //this.accessCtrlSetting.helpURL.url=helpURL;
          this.accessCtrlSetting.helpURL.url = 'https://spherewms.atlassian.net/wiki/spaces/SVUG/pages';
          this.accessCtrlSetting.helpURL.winName = winname;
        }
      }
    }
  }
  login(payload: any): Observable<any> {
    let userData = null;
    return new Observable<any>(observer => {

      this.gemxPost({
        gconn: '',
        gcmd: [payload.username, payload.password, '*', 'XML1'].join('|')
      }).subscribe(x => {
        console.log("Response from V1 Server\n", x);
        let res = x.toString()
        if (res.startsWith('004')) {
          let start = res.indexOf('004') + 3;
          let end = res.lastIndexOf('999');
          res = res.substring(start, end);
          let accounts = res.split('|');
          let splitAccounts = accounts.map((x) => {
            let obj = x.split('\x7F'); // Split by DEL character, used to separate title from accoun ID
            return {
              title: (obj[0] + '').trim().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
              userName: (obj[1] + '').trim()
            }
          });
          console.log("Formated Acccounts:", splitAccounts);
          this.multipleUsers = splitAccounts;
          this.SetV1UserDetailOnLocal(payload);
          this.EnvVariablesTabSettAndToogleAccess(x.split('\n'));
          observer.next({ type: 'success', nextData: 'accounts' });

        } else if (res.startsWith('003')) {

          //0030|0|ERROR|Incorrect user name or password, please try again|OK
          // 002
          // 999
          let errorLines = res.split('\n');
          let error = errorLines[0].split('|');
          observer.next({ type: 'failed', nextData: 'custom', msg: error[3] });
        }
        else {
          let splitRes = res.split('\n');
          this.SetV1UserDetailOnLocal(payload);
          //Pass all line to Set Tab Title Function
          this.EnvVariablesTabSettAndToogleAccess(splitRes);
          splitRes.forEach(line => {
            if (line.startsWith('104')) {
              this.ConstructClientsList(res.substr(3));
              if (this.clinetArrayConfig.available_more_rows) {
                this.LoadCompleteClients().subscribe(clAll => {
                  if (clAll == 'Done') {
                    observer.next({ type: 'success', nextData: 'clients' });
                    observer.complete();
                  }
                });
              } else {
                observer.next({ type: 'success', nextData: 'clients' });
                observer.complete();
              }
            } else if (line.startsWith('110')) {
              this.isDashboardAccount = false;
              this.onMenuResponse.emit(res);
              if (this.clinets.length == 0 && this.isClientSelecionExistinResponse(line)) {
                this.gemxPost({
                  gconn: this.gConn,
                  gcmd: '[MENU]0000'
                }).subscribe(r => {
                  console.log("Got Client List fron v1user", r);
                  let splitClientListRes = r.split('\n');
                  splitClientListRes.forEach(element => {
                    if (element.startsWith('104')) {
                      this.ConstructClientsList(element.substr(3));
                      if (this.clinets.length > 0) {
                        for (let clIndex = 0; clIndex < this.clinets.length; clIndex++) {
                          if ((this.clinets[clIndex].name + '').trim() == this.clientNameFromMenu) {
                            this.clinets[clIndex].isSelected = true;
                          }
                        }
                        let tempClient = Object.assign([], this.clinets);
                        this.clinets = [];
                        this.clinets = tempClient;
                      }
                      if (this.clinetArrayConfig.available_more_rows) {
                        this.LoadCompleteClients().subscribe(clAll => {
                          if (clAll == 'Done') {
                            this.gemxPost({
                              gconn: this.gConn,
                              gcmd: '[RAW][ESC]'
                            }).subscribe(esc => {
                              observer.next({ type: 'success', nextData: 'menu' });
                              observer.complete();
                            });
                          }
                        });
                      } else {
                        this.gemxPost({
                          gconn: this.gConn,
                          gcmd: '[RAW][ESC]'
                        }).subscribe(esc => {
                          observer.next({ type: 'success', nextData: 'menu' });
                          observer.complete();
                        });

                      }
                    }
                  });

                })
              } else {
                observer.next({ type: 'success', nextData: 'menu' });
                observer.complete();
              }
            } else if (line.startsWith('1320')) {
              this.isDashboardAccount = true;
              this.gemxPost({
                gconn: this.gConn,
                gcmd: '[DASHB][LMCLICK]1'
              }).subscribe(r => {
                this.onMenuResponse.emit(r.toString());
                let splitRes = r.split('\n');
                let line = splitRes.filter(data => data.startsWith('110'));
                if (this.clinets.length == 0 && this.isClientSelecionExistinResponse(line[0])) {
                  this.gemxPost({
                    gconn: this.gConn,
                    gcmd: '[MENU]0000'
                  }).subscribe(r => {
                    console.log("Got Client List fron v1user", r);
                    let splitClientListRes = r.split('\n');
                    splitClientListRes.forEach(element => {
                      if (element.startsWith('104')) {
                        this.ConstructClientsList(element.substr(3));
                        if (this.clinets.length > 0) {
                          for (let clIndex = 0; clIndex < this.clinets.length; clIndex++) {
                            if ((this.clinets[clIndex].name + '').trim() == this.clientNameFromMenu) {
                              this.clinets[clIndex].isSelected = true;
                            }
                          }
                          let tempClient = Object.assign([], this.clinets);
                          this.clinets = [];
                          this.clinets = tempClient;
                          console.log(this.clinets);
                        }
                        if (this.clinetArrayConfig.available_more_rows) {
                          this.LoadCompleteClients().subscribe(clAll => {
                            if (clAll == 'Done') {
                              this.gemxPost({
                                gconn: this.gConn,
                                gcmd: '[RAW][ESC]'
                              }).subscribe(esc => {
                                observer.next({ type: 'success', nextData: 'menu' });
                                observer.complete();
                              });
                            }
                          });
                        } else {
                          this.gemxPost({
                            gconn: this.gConn,
                            gcmd: '[RAW][ESC]'
                          }).subscribe(esc => {
                            observer.next({ type: 'success', nextData: 'menu' });
                            observer.complete();
                          });

                        }
                      }
                    });

                  })
                } else {
                  observer.next({ type: 'success', nextData: 'menu' });
                  observer.complete();
                }
              });
            }
          });

        }
      },
        error => {
          console.log("Response Error From V1 Sesrver\n", error);
          observer.complete();
        });

    });
  }

  LoginToSpecificAccount(accountName): Observable<any> {
    return new Observable<any>(observer => {
      if (accountName && accountName.length > 0) {
        this.gemxPost({
          gconn: '',
          gcmd: [this.currentUserValue.masterUser, this.currentUserValue.password, accountName, 'XML1'].join('|')
        }).subscribe(x => {
          console.log("Specific User After Login ", x);
          //Pass all line to Set Tab Title Function
          let splitRes = x.split('\n');
          this.EnvVariablesTabSettAndToogleAccess(splitRes);

          observer.next(x);
        });
      } else {
        window.location.href = '/login';
      }
    });

  }
  SetV1UserDetailOnLocal(payload) {
    this.SetUserDetailOnLocal({
      name: '',
      designation: this.currentUserValue ? this.currentUserValue.designation : '',
      role: 'admin',
      imgUrl: '',
      companyLogo: '/assets/icon/spherewms.svg',
      masterUser: payload.username,
      password: payload.password
    });
  }
  SetUserDetailOnLocal(userData) {
    this.currentUserSubject.next(userData);
    sessionStorage.setItem('currentUser', JSON.stringify(userData));
  }
  logout() {
    return new Observable<any>(observer => {
      let previousSessionKey = JSON.parse(sessionStorage.getItem('sessionKey'));
      if (previousSessionKey) {
        this.logoutCMD(previousSessionKey).subscribe(sk => {
          console.log("Previous Session Logout Successfully:");
          if ((JSON.parse(sessionStorage.getItem('sessionKey'))) == previousSessionKey) {
            sessionStorage.removeItem('sessionKey');
            sessionStorage.removeItem('currentUser');
            this.currentUserSubject.next(null);
          }
          observer.next();
          observer.complete();
        });
      }
    });
  }
  logoutCMD(_sessionKey) {
    return this.gemxPost({
      gconn: _sessionKey,
      gcmd: '[APP][QUIT]'
    }, true);
  }
  saveSubDoaminToTabSession(url) {
    sessionStorage.setItem('currentSubDomain', this.parseSubDomainFromURL(url));
  }
  getSubDomainFromSession() {
    return sessionStorage.getItem('currentSubDomain')
  }
  saveDoaminToTabSession(url) {
    sessionStorage.setItem('currentDomain', url);
  }
  getDomainFromSession() {
    const logout_url= localStorage.getItem('referer_logout_url') ?? sessionStorage.getItem('currentDomain');
    return logout_url;
  }
  parseSubDomainFromURL(url) {
    const urlParts = new URL(url).hostname.split('.')
    return urlParts[0];

  }
  LoadCompleteClients(): Observable<any> {
    return this.gemxPost({
      gconn: this.gConn,
      gcmd: '[FS][MORE]'
    }).pipe(concatMap((res) => {
      let splitedRes: [any] = res.split('\n');
      let clientLine = splitedRes.find(x => x.startsWith('105'));
      let tempRowsMore;
      if (clientLine) {
        tempRowsMore = this.FastScanMore(clientLine.substr(3));
        this.clinetArrayConfig.available_more_rows = tempRowsMore.moreRows;
        let _lastLazyLoadedCount = this.clinets[this.clinets.length - 1].lazyLoadedCount;
        _lastLazyLoadedCount = _lastLazyLoadedCount + 1;
        this.clinets = this.clinets.concat(tempRowsMore.rowsData.map(x => {
          const clientCode = (x[2] + '').trim().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
          return {
            id: x[0],
            name: (x[1] + '').trim().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>') + ' - ' + clientCode,
            isSelected: false,
            lazyLoadedCount: _lastLazyLoadedCount
          }
        }));
      }

      if (tempRowsMore?.moreRows) {
        return this.LoadCompleteClients();
      } else {
        return new Observable<any>((ob) => { ob.next("Done"); ob.complete(); });
      }
    }));
  }
  SearchClients(value) {
    this.gemxPost({
      gconn: this.gConn,
      gcmd: '[RAW]' + value
    }).subscribe(sk => {
      console.log("Search Clients");
      let splitRes = sk.split('\n');
      splitRes.forEach(line => {
        if (line.startsWith('104')) {
          this.ConstructClientsList(line.substr(3));
        }
      });
    });
  }
  ConstructClientsList(response) {
    let temp = this.ParseGrid(response);
    console.log("Parsed Clients:\n", temp.headers, "\nRows:\n", temp.data);
    this.clinets = temp.data.map(x => {
      const clientCode = (x[2] + '').trim().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>')
      return {
        id: x[0],
        name: (x[1] + '').trim().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>') + ' - ' + clientCode,
        isSelected: false,
        lazyLoadedCount: 0
      }
    });
    this.clinetArrayConfig.allow_search = temp.search;
    this.clinetArrayConfig.available_more_rows = temp.more;
    return temp;
  }
  ConstructFunctionKeysList(res) {
    let splitRes: any[] = res.split('\n');
    let isQFExist = splitRes.find(x => x.startsWith('111'));
    if (isQFExist) {
      this.functionKeys = [];
      this.qfddItems = [];
      splitRes.forEach(qf => {
        if (qf.startsWith('111')) {
          let splitLine = (qf.substring(3)).split('|');
          //if Key text exist in response , means QFunctionality command exist on server
          var fkeyExist = (splitLine[1] + '').trim();
          if (fkeyExist != '') {
            let tempObj = {
              keynum: +splitLine[0],
              text: 'QF' + splitLine[0].toString() + ' ' + splitLine[1],
              cmd: '[FKEY]' + splitLine[0]
            }
            if (!tempObj.text.toLowerCase().includes('help')) {
              this.functionKeys.push(tempObj);
              this.qfddItems.push(tempObj);
            }
          }
        }
      });
      console.log("Function Keys:", this.functionKeys);
    }
  }
  ClearAndSetSession(session) {
    let first_command = session.substr(0, 3);
    let pf_unix_socktag = session.substring(3);
    let previousSessionKey = JSON.parse(sessionStorage.getItem('sessionKey'));
    if (previousSessionKey && previousSessionKey.length > 0) {
      this.gemxPost({
        gconn: previousSessionKey,
        gcmd: '[APP][QUIT]'
      }, true).subscribe(sk => {
        console.log("Previous Session Logout Successfully:",)
      });
    }
    if (first_command.match(/(002|003|004|005|007|008|009|133)/)) {
      //pInArray.unshift(session);
    } else {
      if (!pf_unix_socktag.match(/^\/tmp\/gemxsrv-[0-9]+-[0-9]+/)) {
        alert('There was an issue reading the server response');
        return;
      } else {
        this.gConn = pf_unix_socktag;
        sessionStorage.setItem('sessionKey', JSON.stringify(this.gConn));
      }
    }
  }
  ParseLoginDetail(res): Observable<any> {
    return new Observable<any>(observer => {
      let splitedByLineEnd: [any] = res.split('\n');
      console.log("Splited\n\n", splitedByLineEnd);
      //  this.EnvVariablesTabSettAndToogleAccess(splitedByLineEnd);
      for (let i = 0; i < splitedByLineEnd.length; i++) {
        let data = (splitedByLineEnd[i] + '');
        if (data.startsWith('110')) {
          this.onMenuResponse.emit(res);
          if (this.clinets.length == 0 && this.isClientSelecionExistinResponse(data)) {
            this.gemxPost({
              gconn: this.gConn,
              gcmd: '[MENU]0000'
            }).subscribe(r => {
              console.log("Got Client List fron v1user", r);
              let splitClientListRes = r.split('\n');
              splitClientListRes.forEach(element => {
                if (element.startsWith('104')) {
                  this.ConstructClientsList(element.substr(3));
                  if (this.clinets.length > 0) {
                    for (let clIndex = 0; clIndex < this.clinets.length; clIndex++) {
                      if ((this.clinets[clIndex].name + '').trim() == this.clientNameFromMenu) {
                        this.clinets[clIndex].isSelected = true;
                      }
                    }
                    let tempClient = Object.assign([], this.clinets);
                    this.clinets = [];
                    this.clinets = tempClient;
                    console.log(this.clinets);
                  }
                  if (this.clinetArrayConfig.available_more_rows) {
                    this.LoadCompleteClients().subscribe(clAll => {
                      if (clAll == 'Done') {
                        if (sessionStorage.getItem('currentClient')) {
                          observer.next({ isClientSelection: false, isSelectedClient: true, response: null });
                          observer.complete();
                        } else {
                          this.gemxPost({
                            gconn: this.gConn,
                            gcmd: '[RAW][ESC]'
                          }).subscribe(esc => {
                            observer.next({ isClientSelection: false, response: null });
                            observer.complete();
                          });
                        }
                      }
                    });
                  } else {
                    if (sessionStorage.getItem('currentClient')) {
                      observer.next({ isClientSelection: false, isSelectedClient: true, response: null });
                      observer.complete();
                    } else {
                      this.gemxPost({
                        gconn: this.gConn,
                        gcmd: '[RAW][ESC]'
                      }).subscribe(esc => {
                        observer.next({ isClientSelection: false, response: null });
                        observer.complete();
                      });
                    }
                  }

                }
              });

            })
          } else {
            observer.next({ isClientSelection: false, isDashboard: false, response: null });
            observer.complete();
          }
        } else if (data.startsWith('104')) {
          let parsedData = this.ConstructClientsList(data.substr(3));
          if (this.clinetArrayConfig.available_more_rows) {
            this.LoadCompleteClients().subscribe(clAll => {
              if (clAll == 'Done') {
                observer.next({ isClientSelection: true, isDashboard: false, response: null });
                observer.complete();
              }
            });
          } else {
            observer.next({ isClientSelection: true, isDashboard: false, response: null });
            observer.complete();
          }

        }
      }
      if (splitedByLineEnd.find(x => x.startsWith('1320'))) {
        observer.next({ isClientSelection: false, isDashboard: true, response: splitedByLineEnd });
        observer.complete();
      }
    });
  }

  onPageLoadGetUserData() {
    if (this.currentUserValue?.designation) {
      console.log("");
      this.login({ username: this.currentUserValue.masterUser, password: this.currentUserValue.password }).subscribe(y => {
        if (y.type == 'failed') {
          this.hideJiraTicket();
          this.logout().subscribe(z => {
            window.location.href = '/login';
          });
        }
        else if (y.type == 'success' && y.nextData == 'menu') {
          this.impMobCallOnPageReload();
          // this.afterPageLoaded.emit({ type: 'pageLoad' });
        } else {

          if (y.type == 'success' && y.nextData == 'accounts') {
            this.LoginToSpecificAccount(this.currentUserValue.designation).subscribe(x => {
              this.ParseLoginDetail(x).subscribe(y => {
                if (y.isClientSelection == true) {
                  this.onPageLoadClientSelectionCallOut();
                } else if (y.isSelectedClient == true) {
                  this.onPageLoadClientSelectionCallOut();
                } else {
                  if (y.isDashboard == true) {
                    this.onDashboardResponseRedirectToMenu(y.response).subscribe(tt => {

                      this.impMobCallOnPageReload();
                    })
                  } else {

                    this.impMobCallOnPageReload();
                  }
                };
              });
            })
          } else if (y.type == 'success' && y.nextData == 'clients') {
            this.onPageLoadClientSelectionCallOut();
          }
        }
      });
    } else {
      window.location.href = '/login';
    }


  }
  onPageLoadClientSelectionCallOut() {
    let currentClientObj = JSON.parse(sessionStorage.getItem('currentClient'));
    let currentClientID = currentClientObj.clientRowId;
    this.ClientSelectionProcedure(currentClientID).subscribe(cl => {
      this.impMobCallOnPageReload();
    });
  }

  LoadJiraticket(accountId) {
    let url = "/scripts/get_jira_key.php";
    // Request headers
    let headers = new HttpHeaders();
    headers = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic 1234'
    });
    let data = {
      account: accountId
    }
    let body = new HttpParams();
    for (const k in data) {
      body = body.set(k, data[k]);
    }
    this.Http.post(this.apiEnvService.API_Config.gemxUrl + url, body, { headers: headers }).subscribe((x: any) => {
      if (x.ticket.jira_key) {
        let data_key;
        const hostname = window && window.location && window.location.hostname;
        let isFeatureOrStagingORSandBox = hostname.split('.').find(x => (x == this.apiEnvService.featureDomainName || x == 'sandbox'));
        if (/^.*localhost.*/.test(hostname) || isFeatureOrStagingORSandBox) {
          data_key = '78b18901-17df-429f-80a0-14602d3071f5';
        } else {
          data_key = x.ticket.jira_key;
        }
        //Check if Script is already loaded with same data-key
        if (!document.getElementById('jsd-widget-script')) {
          // Initialize the JIRA widget
          let jhdScript = document.createElement('script');
          jhdScript.type = 'text/javascript';
          jhdScript.setAttribute('id', 'jsd-widget-script');
          jhdScript.setAttribute('data-jsd-embedded', null);
          jhdScript.setAttribute('data-key', data_key);
          jhdScript.setAttribute('class-position', this.clinets.length > 0 ? 'with-clients' : 'with-out-clients')
          jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
          jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
          jhdScript.onload = function () {
            window.document.addEventListener('DOMContentLoaded', (event) => {
              var iframe: any = window.document.getElementById('jsd-widget');
              var style = document.createElement('style');
              style.textContent = `
                #button-container{
                  padding: 0px !important;
                  margin-top:8px !important;
                  width: 18px !important;
                }

                #button-container #help-button.icon, #button-container #help-button.text{
                  // width:181px !important;
                }

                #button-container #help-button.text {
                  // margin-left: 57px !important;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                #button-container #help-button {
                  height: 33px !important;
                  background-color: #74C4E4 !important;
                  width: 181px !important;
                  border-radius: 25px;
                  display: flex !important;
                  justify-content: center !important;
                  align-items: center !important;
                }

                #button-container #help-button:hover {
                  background-color: #74C4E4 !important;
                  box-shadow: 0 0 0 2px rgba(222,235,255,0.9);
                }

                #button-container #help-button:after {
                  content: 'Submit a Ticket';
                  font-size: 15px;
                  font-family: "roboto", sans-serif !important;
                }

                #button-container #help-button .help-button-contents{
                    font-size: 16px;
                    font-family: "roboto", sans-serif !important;
                    display: none !important;
                }

                .tooltip {
                  position: relative;
                  display: inline-block;
                  width: 100%;
                }

                .tooltip .tooltiptext {
                  visibility: hidden;
                  width: 120px;
                  background-color: #34495E;
                  color: #fff;
                  text-align: center;
                  border-radius: 6px;
                  padding: 8px 0;
                  position: absolute;
                  z-index: 1;
                  bottom: -45px;
                  left: 77%;
                  margin-left: -60px;
                  opacity: 0;
                  transition: opacity 0.3s;
                }

                .tooltip .tooltiptext::after {
                  content: " ";
                  position: absolute;
                  bottom: 100%;
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: transparent transparent #34495E transparent;
                }

                .tooltip:hover .tooltiptext {
                  visibility: visible;
                  opacity: 1;
                }

                .custom-dimensions {
                  height: 546px !important;
                  width: 90px !important;
                  transition: width 0s, height 0s;
                }`
                ;

              iframe.contentDocument.head.appendChild(style);
              if (window.document.getElementsByTagName('app-home')) {
                let header = document.getElementsByClassName('header-body')
                if (header.length > 0) {
                  iframe.style.visibility = 'initial';
                }


                let _calclClassFrmElement = window.document.getElementById('jsd-widget-script');
                if (_calclClassFrmElement) {
                  let _classApplied = _calclClassFrmElement.getAttribute("class-position");
                  if (_classApplied) {
                    iframe.classList.add(_classApplied);
                  }
                }
              }
              console.log('Jira Content Loaded');
            });
            var DOMContentLoaded_event = document.createEvent('Event');
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
          }
          //Hide For demo purpose,use slashes
          //document.getElementsByTagName('head')[0].appendChild(jhdScript);
          document.getElementsByTagName('head')[0].appendChild(jhdScript);
        }
      }
      if (x.chat.jira_id && x.chat.service_desk_id) {
        let jiraChatWidget = document.createElement('chat-widget');
        jiraChatWidget.setAttribute('jira-id', x.chat.jira_id);
        jiraChatWidget.setAttribute('service-desk-id', x.chat.service_desk_id);
        document.getElementsByTagName('body')[0].appendChild(jiraChatWidget);
        let jiraChatScript = document.createElement('script');
        jiraChatScript.src = 'https://chat-api.spartez-software.com/chat-widget.js';
        document.getElementsByTagName('body')[0].appendChild(jiraChatScript);
      }
    });
  }
  hideJiraTicket() {
    var iframe: any = document.getElementById('jsd-widget');
    if (iframe) {
      iframe.style.visibility = 'hidden';
    }
  }
  ParseGrid(response) {
    // Delimiters
    let data = [];
    let dMaster = '~';
    let dRow = '|';
    let dCell = String.fromCharCode(127);
    let dImage = String.fromCharCode(3);
    let fastscanData = response.split(dMaster);
    let tableData = fastscanData[8].split(dRow);

    // Build Data Object
    let row = fastscanData[0];
    let col = fastscanData[1];
    let rows = fastscanData[2];
    let width = fastscanData[3];
    let search = (fastscanData[4] ? true : false);
    let mode = (fastscanData[5] == 0 ? false : true);
    let more = (fastscanData[6] == 0 ? true : false);
    let title = fastscanData[7];

    let headers = [];
    let load_all = false;
    for (var ii = 0; ii < tableData.length; ii++) {
      if (ii == 0) {
        var header = tableData[ii].split(dCell);
        for (var jj = 0; jj < header.length; jj++) {
          let headerObj: any = {};
          headerObj.name = header[jj].substring(4);
          headerObj.width = header[jj].substring(1, 4);
          headerObj.percent = (header.width / width * 100);
          //headerObj.type = (header[jj].substring(0,1) == 'C' ? 'char' : 'date');
          headers.push(headerObj);
        }

      } else {
        // Check the data for an image
        if (tableData[ii].indexOf(dImage) !== -1) {
          var image_row = tableData[ii].split(dCell);
          for (var cell_key in image_row) {
            if (image_row[cell_key].indexOf(dImage) !== -1) {

              // Is an image (uses dImage delimiter)
              if (!image_row[cell_key].match(/^\x03[0-9]{2}/)) {
                var imageData = image_row[cell_key];
                imageData = imageData.replace(dImage, '');
                imageData = imageData.split(';');

                var thumb, image = null;
                for (var image_key in imageData) {
                  if (imageData[image_key].substr(0, 1) == 'T')
                    thumb = imageData[image_key].substr(1);
                  else
                    image = imageData[image_key];
                }

                if (image == ' ') {
                  image = '/empty';
                }

                if (image || thumb) {
                  image_row[cell_key] = '<a href="images' + image + '?tn=' + encodeURIComponent('images' + thumb) + '" data-lightbox="fs_image"><img src="images' + thumb + '" class="thumbnail" width="64"></a>';
                }
              }
            }
          }
          tableData[ii] = image_row.join(dCell);
        }
        data.push(tableData[ii].split(dCell));
      }
    }
    return { headers, data, title, rows, search, mode, more };
  }
  GetV3FormatedGrid(obj, clickableColName?) {
    let cols: [any] = obj.headers.map((x, i) => {
      return {
        displayName: x.name,
        field: 'index' + i,
        isVisible: true,
        isDragable: true,
        clickable: clickableColName !== undefined ? (x.name == clickableColName) : false
      }
    });
    let data = obj.data.map((x, i) => {
      let row = {};
      x.forEach((element, index) => {
        if (index !== 0) {
          row['index' + (index - 1)] = element;
        }
      });
      row['index'] = i;
      return row;
    });
    return { cols, data };
  }
  ClientSelectionProcedure(selectedClientRowId) {
    return new Observable<any>(observer => {
      this.gemxPost({
        gconn: this.gConn,
        gcmd: '[FS]' + selectedClientRowId
      }).subscribe(men => {
        let splitRes: [any] = men.split('\n');
        if (splitRes.find(mr => mr.startsWith('110'))) {
          console.log("Menu Data Got After User Select Client", men);
          this.onMenuResponse.emit(men);
          this.EnvVariablesTabSettAndToogleAccess(splitRes);
          for (let cl = 0; cl < this.clinets.length; cl++) {
            this.clinets[cl].isSelected = false;
            if (this.clinets[cl].id == selectedClientRowId) {
              this.clinets[cl].isSelected = true;
              sessionStorage.setItem('currentClient', JSON.stringify({
                clientRowId: this.clinets[cl].id,
                lazyLoadedCount: this.clinets[cl].lazyLoadedCount
              }));
            }
          }
          let tempClient = Object.assign([], this.clinets);
          this.clinets = [];
          this.clinets = tempClient;
          observer.next(true);
          observer.complete();
        } else {
          this.onDashboardResponseRedirectToMenu(splitRes, selectedClientRowId).subscribe(tt => {
            observer.next(true);
            observer.complete();
          });
        }
      });
    });
  }
  onDashboardResponseRedirectToMenu(splitResMethArg: [any], selectedClientRowId?) {
    return new Observable<any>((obsrvr) => {
      let command = '[RAW][ESC]';
      let _dbTitle = splitResMethArg.find(mr => mr.startsWith('1320'))
      if (_dbTitle) {
        if ((((_dbTitle + '').split('|')[2]).trim().toLowerCase()) == 'global dashboard') {
          this.isGlobalDashboardOnLanding = true;
        }
        this.isDashboardAccount = true;
        command = '[DASHB][LMCLICK]1';
      }
      this.gemxPost({
        gconn: this.gConn,
        gcmd: command
      }).subscribe(ds => {
        this.onMenuResponse.emit(ds);
        let splitMen: [any] = ds.split('\n');
        this.EnvVariablesTabSettAndToogleAccess(splitMen);
        let menuLine = splitMen.find(f => f.startsWith('110'));
        if (!selectedClientRowId && this.clinets.length == 0 && this.isClientSelecionExistinResponse(menuLine)) {
          this.gemxPost({
            gconn: this.gConn,
            gcmd: '[MENU]0000'
          }).subscribe(r => {
            let splitClientListRes = r.split('\n');
            splitClientListRes.forEach(element => {
              if (element.startsWith('104')) {
                this.ConstructClientsList(element.substr(3));
                if (this.clinets.length > 0) {
                  for (let clIndex = 0; clIndex < this.clinets.length; clIndex++) {
                    if ((this.clinets[clIndex].name + '').trim() == this.clientNameFromMenu) {
                      this.clinets[clIndex].isSelected = true;
                    }
                  }
                  let tempClient = Object.assign([], this.clinets);
                  this.clinets = [];
                  this.clinets = tempClient;
                }
                if (this.clinetArrayConfig.available_more_rows) {
                  this.LoadCompleteClients().subscribe(clAll => {
                    if (clAll == 'Done') {
                      this.gemxPost({
                        gconn: this.gConn,
                        gcmd: '[RAW][ESC]'
                      }).subscribe(esc => {
                        obsrvr.next();
                        obsrvr.complete();
                      });
                    }
                  });
                } else {
                  this.gemxPost({
                    gconn: this.gConn,
                    gcmd: '[RAW][ESC]'
                  }).subscribe(esc => {
                    obsrvr.next();
                    obsrvr.complete();
                  });

                }
              }
            });

          })
        } else {
          for (let cl = 0; cl < this.clinets.length; cl++) {
            if (this.clinets[cl].id == selectedClientRowId) {
              this.clinets[cl].isSelected = true;
              sessionStorage.setItem('currentClient', JSON.stringify({
                clientRowId: this.clinets[cl].id,
                lazyLoadedCount: this.clinets[cl].lazyLoadedCount
              }));
            }
          }
          let tempClient = Object.assign([], this.clinets);
          this.clinets = [];
          this.clinets = tempClient;
          obsrvr.next();
          obsrvr.complete();
        }

      });
    })
  }
  lazyLoadClientsOnPageLoad(cmds: string[]) {
    return new Observable<any>(observer => {
      if (cmds.length > 0) {
        from(cmds).pipe(
          concatMap((cmd, index) => {
            return new Observable<any>(observer => {
              this.gemxPost({
                gconn: this.gConn,
                gcmd: cmd
              }).subscribe(x => {
                let splitedByLineEnd = x.split('\n');
                for (let i = 0; i < splitedByLineEnd.length; i++) {
                  let data = (splitedByLineEnd[i] + '');
                  if (data.startsWith('105')) {
                    let tempRowsMore = this.FastScanMore(data.substr(3));
                    this.clinetArrayConfig.available_more_rows = tempRowsMore.moreRows;
                    let _lastLazyLoadedCount = this.clinets[this.clinets.length - 1].lazyLoadedCount;
                    _lastLazyLoadedCount = _lastLazyLoadedCount + 1;
                    this.clinets = this.clinets.concat(tempRowsMore.rowsData.map(x => {
                      return {
                        id: x[0],
                        name: (x[1] + '').trim().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
                        isSelected: false,
                        lazyLoadedCount: _lastLazyLoadedCount
                      }
                    }));
                    if (index == cmds.length - 1) {
                      observer.next('Completed');
                    } else {
                      observer.next('Navigating');
                    }
                    observer.complete();
                  }
                }
              })
            });
          })
        ).subscribe(x => {
          if (x == 'Completed') {
            observer.next(x);
            observer.complete();
          }
        });
      } else {
        observer.next('commands lenght is 0');
        observer.complete();
      }
    });
  }
  FastScanMore(response) {
    // Delimiters
    let dMaster = '~';
    let dRow = '|';
    let dCell = String.fromCharCode(127);
    let dImage = String.fromCharCode(3);

    let moreRows = (response.substring(0, 1) == 1 ? false : true);


    let tableData = response.substring(1).split(dRow);
    let rowsData = [];
    for (let ii = 0; ii < tableData.length; ii++) {
      // Check the data for an image
      if (tableData[ii].indexOf(dImage) !== -1 && !tableData[ii].match(/^[0-9]{1,2}/)) {
        let image_row = tableData[ii].split(dCell);

        for (var cell_key in image_row) {
          if (image_row[cell_key].indexOf(dImage) !== -1) {
            let imageData = image_row[cell_key];
            imageData = imageData.replace(dImage, '');
            imageData = imageData.split(';');

            let thumb, image = null;
            for (var image_key in imageData) {
              if (imageData[image_key].substr(0, 1) == 'T')
                thumb = imageData[image_key].substr(1);
              else
                image = imageData[image_key];
            }

            if (image == ' ') {
              image = '/empty';
            }

            if (image || thumb) {
              image_row[cell_key] = '<a href="images' + image + '?tn=' + encodeURIComponent('images' + thumb) + '" data-lightbox="fs_image"><img src="images' + thumb + '" class="thumbnail" width="64"></a>';
            }
          }
        }
        tableData[ii] = image_row.join(dCell);
      }
      rowsData.push(tableData[ii].split(dCell))
    }
    return { rowsData, moreRows }

  }
  isClientSelecionExistinResponse(res: string) {
    if (res && res.length > 0) {
      let clinetSelectiontextStart = res.indexOf('0000~');
      if (clinetSelectiontextStart !== -1) {
        let clinetSelectionMenuText = res.substr(clinetSelectiontextStart, 35);
        if (((clinetSelectionMenuText + '').toLowerCase()).includes('select/change client')) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }

  }
  get SelectedClientName() {
    let currentClientObj = JSON.parse(sessionStorage.getItem('currentClient'));
    let currentClientID = currentClientObj?.clientRowId;
    //console.warn("currentClient From Local Storage",currentClientID);
    if (currentClientID) return this.clinets.find(x => x.id == currentClientID)?.name;
    else return '';
  }

  checkMFASettings(credentials: Credentials) {
    // initialinzing form data
    const formData = new FormData();
    formData.append('username', credentials.username);
    formData.append('password', credentials.password);
    // headers
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.Http.post<MFAResponse>(`${this.apiEnvService.API_Config.gemxUrl}/scripts/checkMFASettings.php`, formData, { headers });
  }

  validateTOTP(totpData: ValidateTOTP) {
    // initialinzing form data
    const formData = new FormData();
    formData.append('username', totpData.username);
    formData.append('code', totpData.code);
    // headers
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.Http.post<TOTPResponse>(`${this.apiEnvService.API_Config.gemxUrl}/scripts/validateTotp.php`, formData, { headers });
  }

  impMenufunc(check): Observable<any> {
    return new Observable<any>(observer => {
      if (check == "99") {
        this.gemxPost({
          gconn: this.gConn,
          gcmd: '[RAW]imp'
        }).subscribe(impMenu => {
          this.onImpMenuResponse.emit(impMenu);
          observer.next(impMenu);
          observer.complete();
        })
      } else {
        observer.next();
        observer.complete();
      }


    })
  }

  mobMenufunc(): Observable<any> {
    return new Observable<any>(observer => {
      this.gemxPost({
        gconn: this.gConn,
        gcmd: '[RAW]mob'
      }).subscribe(mobMenu => {
        this.onMobMenuResponse.emit(mobMenu);
        observer.next(mobMenu);
        observer.complete();
      })
    })
  }

  adminBackendAPI(): Observable<any> {
    return new Observable<any>(observer => {
      let headersforIMPcheck = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Environment': `${this.currentUserValue.designation}`,
        'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sYXJhdmVsLnRlc3RcL1wvbG9naW4iLCJpYXQiOjE2MTkwMzc2MzgsImV4cCI6MTYxOTA0MTIzOCwibmJmIjoxNjE5MDM3NjM4LCJqdGkiOiJ1eG9xUDVlQnF4ZndXMExBIiwic3ViIjozLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.BVgE7-oRaPKnTEs8sSXFAOh7BB65sHpGRELfS4trDbw`
      });
      let paramsforIMPcheck = new HttpParams().set('username', this.currentUserValue.masterUser);

      this.Http.get(`${this.backendApiURL}V1_0_0/auth1/admin`, { headers: headersforIMPcheck, params: paramsforIMPcheck, withCredentials: true }).subscribe((data: any) => {

        if (data && data[0]) {
          observer.next(data[0]);
          observer.complete();
        } else {
          observer.next(false);
          observer.complete();
        }
      })
    })
  }

  navigateToMenuSection(loadedScreen?, impMenu?, mobMenu?) {
    const matchMenu: (prev, next) => boolean = (prev, next) => {
      let splitedPrevRes: [] = prev.split('\n');
      let splitedNextRes: [] = next.split('\n');
      return splitedPrevRes.find(pr => (pr + '').startsWith('110')) === splitedNextRes.find(nr => (nr + '').startsWith('110'))
    }
    const responseParser: (res) => any = (res) => {
      return res === 'onMenuScreen' ? 'PASS' :
        (res === 'BackEndStuck' ? 'FAIL' :
          (res === 'BackEndStuckOnError' ? 'FailWithError' :
            (res === 'SessionExpired' ? res : 'FAIL')));
    }
    const iterativeReq: (isMenu, prevScreen, notNavigationToBack?: number) => Observable<any> = (isMenu: boolean, prevScreen: string, notNavigationToBack?: number) => {
      if (isMenu) {
        this.ConstructFunctionKeysList(prevScreen);
        return this.gemxPost({
          gconn: this.gConn,
          gcmd: '[MENU][ESC]'
        }).pipe(concatMap((res) => {
          if (matchMenu(res, prevScreen)) {
            this.ConstructFunctionKeysList(res);
            return new Observable<any>((ob) => { ob.next("onMenuScreen"); ob.complete(); });
          } else {
            return iterativeReq(true, res);
          }
        }));
      } else {
        return this.gemxPost({
          gconn: this.gConn,
          gcmd: '[RAW][ESC]'
        }).pipe(concatMap((res) => {
          if (res === prevScreen) {
            if (notNavigationToBack && notNavigationToBack > 2) {
              let resLines = this.v1Utiltity.parseResponse(res);
              if (resLines.find(f => f.code == '003')) {
                return new Observable<any>((ob) => { ob.next("SessionExpired"); ob.complete(); });
              } else {
                return new Observable<any>((ob) => { ob.next("BackEndStuck"); ob.complete(); });
              }

            } else {
              notNavigationToBack = (!notNavigationToBack) ? 1 : notNavigationToBack + 1;
              return iterativeReq(false, res, notNavigationToBack);
            }
          } else {
            let resLines = this.v1Utiltity.parseResponse(res);
            if (resLines.find(f => f.code == '110')) {
              return iterativeReq(true, res);
            }
            //there is a error msg box ,that why we  cannot go back automtically without user action, so that will
            else if (resLines.find(f => f.code == '109')) {
              return new Observable<any>((ob) => { ob.next("BackEndStuckOnError"); ob.complete(); });
            } else {
              return iterativeReq(false, res);
            }
          }
        }))
      }
    }
    return new Observable<any>(observer => {

      let resLinesLoadScreen = this.v1Utiltity.parseResponse(loadedScreen);

      resLinesLoadScreen.some(d => d.code == '121') ? loadedScreen = undefined : false;
      if (loadedScreen) {

        iterativeReq(resLinesLoadScreen.some(f => f.code == '110'), loadedScreen).subscribe(final => {

          if (impMenu) {

            this.gemxPost({
              gconn: this.gConn,
              gcmd: '[RAW]imp'
            }).subscribe(final2 => {
              observer.next(responseParser(final2));
              observer.complete();
            })
          }
          else if (mobMenu) {

            this.gemxPost({
              gconn: this.gConn,
              gcmd: '[RAW]mob'
            }).subscribe(final3 => {
              observer.next(responseParser(final3));
              observer.complete();
            })
          } else {
            observer.next(responseParser(final));
            observer.complete();
          }
        });
      } else {

        this.gemxPost({
          gconn: this.gConn,
          gcmd: '[RAW]'
        }).pipe(concatMap((currentScreen) => {
          let resLines = this.v1Utiltity.parseResponse(currentScreen);
          return iterativeReq(resLines.some(f => f.code == '110'), currentScreen);
        })).subscribe(final => {


          if (impMenu) {
            this.gemxPost({
              gconn: this.gConn,
              gcmd: '[RAW]imp'
            }).subscribe(final2 => {
              observer.next(responseParser(final2));
              observer.complete();
            })
          } else {
            observer.next(responseParser(final));
            observer.complete();
          }
        });
      }
    });

  }


  impMobCallOnPageReload() {

    let url = this._router.url;
    let urls = (url.startsWith('/') ? url.slice(1) : url).split('/');
    let titleParent = urls[0];

    let formattedUrl = titleParent.replace(/-/g, ' ').replace(/%28/g, '(').replace(/%29/g, ')');
    this.adminBackendAPI().subscribe(adminData => {
      this.isAdminUser = adminData.menu_passwd_no;
      if (adminData.start_menu_id == "CFM100" || adminData.menu_options == "noxit") {
        this.navigateToMenuSection().subscribe(_z1 => {
          this.afterPageLoaded.emit({ type: 'pageLoad' });
        })
      } else {
        this.impMenufunc(adminData.menu_passwd_no).subscribe(imp => {
          this.mobMenufunc().subscribe(mob => {
            let impData;
            let mobData;

            if (imp) {
              let impSplitRes: [any] = imp.split('\n');
              impData = impSplitRes.find(mr => mr.startsWith('110'))
            }
            if (mob) {
              let mobSplitRes: [any] = mob.split('\n');
              mobData = mobSplitRes.find(mr => mr.startsWith('110'))
            }

            //if the current route is mob - direct call to component
            let impRoutes = impData ? this.parseImpData(impData?.toLowerCase()) : null;
            let mobCheck = urls[0].split('-');
            if (mobCheck[0] == 'mobile') {
              titleParent = urls[0].substr(urls[0].indexOf('-') + 1).replace(/-/g, ' ').replace(/%28/g, '(').replace(/%29/g, ')');
            }
            if (mobCheck[0] == 'mobile' && mobData.toLowerCase().includes(titleParent)) {
              this.afterPageLoaded.emit({ type: 'pageLoad' });
            }
            //if the current route is imp - Back to main -> imp -> component
            else if (formattedUrl != "" && impRoutes && impRoutes.includes(formattedUrl)) {
              this.navigateToMenuSection('', true).subscribe(y1 => {
                this.afterPageLoaded.emit({ type: 'pageLoad' });
              })
            }
            //if the current route is main - Back to main -> component
            else {
              this.navigateToMenuSection().subscribe(z1 => {
                this.afterPageLoaded.emit({ type: 'pageLoad' });
              })
            }
          })

        })
      }
    })
  }

  parseImpData(data) {
    let splitedArr = data.split('|');
    splitedArr = splitedArr.map(el => el.split('-')[1]);
    splitedArr = splitedArr.filter(el => el != undefined);
    splitedArr = splitedArr.map(el => el.trim());
    return splitedArr;
  }
  validateToken(token: string): Observable<any> {
    const ssoBaseURL=this.apiEnvService.API_Config.ssoBaseUrl;
    return this.Http.get<any>(`${ssoBaseURL}/api/validate-token?token=${token}`);
  }
  setLogoutURL(url:string){
    localStorage.setItem('referer_logout_url',url);
  }
  removeRefererDomain(){
    localStorage.removeItem('referer_logout_url');
  }
}



export class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return standardEncoding(k); }
  encodeValue(v: string): string { return standardEncoding(v); }
  decodeKey(k: string): string { return decodeURIComponent(k); }
  decodeValue(v: string) { return decodeURIComponent(v); }
}
function standardEncoding(v: string): string {
  return encodeURIComponent(v);
}
